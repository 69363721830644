import { LoopButton, Shadow, Toast } from '@loophealth/loop-ui-web-library';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import {
  resetSingleAddDependantsState,
  selectActiveDependants,
  selectAddEmployeeDetails,
  selectAddEmployeeErrors,
  selectAddSelectedPolicyDetails,
  selectFormSubmissionStatus,
  selectProceedWithoutDeps,
  selectSingleAddCurrentStep,
  selectSingleAddLastStep,
  setAddDependantErrors,
  setAddEmployeeErrors,
  setAddEmployeeIdErrors,
  setFormSubmissionStatus,
  setProceedWithoutDeps,
  updateCurrentStep,
  updateLastStep,
} from '../../../../redux/slices/singleAddSlice';
import { StyledBottomNavigation } from '../../../containers/Bulk/styles';
import { IError } from '../../../pages/Endorsements/ListingPage/types';
import { SINGLE_ADD_STEPS } from '../../../pages/Endorsements/SingleAddMembers/constants';
import {
  getButtonStates,
  validateDependantDetails,
  validateEmployeeDetails,
} from '../../../pages/Endorsements/SingleAddMembers/utils';
import { transformIntoPolicyWiseAddData } from '../ReviewCostScreen/utils';
import { submitBulkAddEndorsement } from '../../Bulk/SubmitEndo/utils';
import CancellationModal from '../../Bulk/CancellationModal';
import { ISingleAddMembersFooter } from './types';
import { isCDLowTrue } from '../../../pages/Endorsements/BulkAddMembers/utils';
import { ADD_FORM_SUBMISSION_STATUS } from '../../../../redux/slices/singleAddSlice/types';
import { trackClickEvent } from '../../../../utils/segment/utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';

const SingleAddMembersFooter: React.FC<ISingleAddMembersFooter> = ({
  endoCostDetails,
}) => {
  const trackClick = useSegment('click');
  const dispatch = useDispatch();
  const toast = Toast.useToast();
  const currentStep = useSelector(
    selectSingleAddCurrentStep,
  ) as SINGLE_ADD_STEPS;
  const lastStep = useSelector(selectSingleAddLastStep) as SINGLE_ADD_STEPS;
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const employeeDetails = useSelector(selectAddEmployeeDetails);
  const employeeErrors = useSelector(selectAddEmployeeErrors);
  const dependentsList = useSelector(selectActiveDependants);
  const selectedCompany = useSelector(selectSelectedCompany);
  const proceedWithoutDeps = useSelector(selectProceedWithoutDeps);
  const companyId = selectedCompany?.id ?? '';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lowCDModalVisible, setLowCDModalVisible] = useState(false);

  useEffect(() => {
    dispatch(resetSingleAddDependantsState());
  }, [dispatch, selectedPolicies]);

  const setCurrentStep = (step: SINGLE_ADD_STEPS) => {
    dispatch(updateLastStep(currentStep));
    dispatch(updateCurrentStep(step));
  };

  const hasDependentsEnabled = useMemo(
    () =>
      Object.values(selectedPolicies).some(
        (policy) => policy.slabFamilyDefinition !== 'Self',
      ),
    [selectedPolicies],
  );

  const {
    isNextEnabled,
    isBackEnabled,
    buttonText,
    backButtonText,
    backButtonVariant,
  } = useMemo(
    () =>
      getButtonStates(
        currentStep,
        selectedPolicies,
        employeeDetails,
        employeeErrors,
        hasDependentsEnabled,
        endoCostDetails.totalLives,
        dependentsList,
      ),
    [
      currentStep,
      endoCostDetails,
      dependentsList,
      employeeDetails,
      employeeErrors,
      hasDependentsEnabled,
      selectedPolicies,
    ],
  );

  const handleNextStep = () => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.CHOOSE_POLICY:
        trackClick(trackClickEvent('Proceed_To_Add_Policy_Selection_Modal_Single_Add'));
        return setCurrentStep(SINGLE_ADD_STEPS.ADD_EMPLOYEE);
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        dispatch(setProceedWithoutDeps(!hasDependentsEnabled));
        if(hasDependentsEnabled)
          trackClick(trackClickEvent('Add_Dependants_Employee_Add_Modal_Single_Add'));
        else 
          trackClick(trackClickEvent('Without_Dependants_Employee_Add_Modal_Single_Add'));
        return processEmployeeDetails(hasDependentsEnabled);
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        trackClick(trackClickEvent('Review_Additional_Cost_Add_Dependant_Modal_Single_Add'));
        return processDependantDetails();
      case SINGLE_ADD_STEPS.REVIEW_COST:
        trackClick(trackClickEvent('Submit_Lives_Review_Additional_Cost_Modal_Single_Add'));
        if (isCDLowTrue(endoCostDetails.endoCostList))
          setLowCDModalVisible(true);
        else submitEndorsementRequest();
    }
  };

  const handleSecondaryClick = () => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        dispatch(setProceedWithoutDeps(true));
        trackClick(trackClickEvent('Add_Dependants_Employee_Add_Modal_Single_Add'));
        return processEmployeeDetails(false);
      case SINGLE_ADD_STEPS.REVIEW_COST:
        trackClick(trackClickEvent('Go_Back_Button_Review_Additional_Cost_Modal_Single_Add'));
        return setCurrentStep(lastStep);
    }
  };

  const processDependantDetails = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const { errors } = await validateDependantDetails(
        companyId,
        selectedPolicies,
        employeeDetails,
        dependentsList,
        false,
      );
      dispatch(setAddDependantErrors(errors));
      if (Object.keys(errors).length === 0) {
        setCurrentStep(SINGLE_ADD_STEPS.REVIEW_COST);
      }
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsSubmitting(false);
  };

  const processEmployeeDetails = async (addDependents: boolean) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const { errors, ...empIdErrors } = await validateEmployeeDetails(
        employeeDetails,
        selectedPolicies,
        companyId,
      );
      dispatch(setAddEmployeeErrors(errors));
      dispatch(setAddEmployeeIdErrors(empIdErrors));
      if (
        !empIdErrors.employeeAlreadyPresent &&
        !empIdErrors.employeePartiallyPresent &&
        Object.keys(errors).length === 0
      ) {
        setCurrentStep(
          addDependents
            ? SINGLE_ADD_STEPS.ADD_DEPENDANTS
            : SINGLE_ADD_STEPS.REVIEW_COST,
        );
      }
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsSubmitting(false);
  };

  const submitEndorsementRequest = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const policyWiseAddData = transformIntoPolicyWiseAddData(
        selectedPolicies,
        employeeDetails,
        dependentsList,
        proceedWithoutDeps,
      );
      const response = await submitBulkAddEndorsement(
        companyId,
        policyWiseAddData,
      );
      if (!response) throw new Error('Something went wrong');
      else setCurrentStep(SINGLE_ADD_STEPS.SUMMARY);
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsSubmitting(false);
  };

  const [primaryLoading, secondaryLoading] = useMemo(() => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        if (isSubmitting) return [!proceedWithoutDeps, proceedWithoutDeps];
        break;
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        return [isSubmitting, false];
      case SINGLE_ADD_STEPS.REVIEW_COST:
        return [endoCostDetails.isLoading || isSubmitting, false];
    }
    return [false, false];
  }, [
    currentStep,
    isSubmitting,
    proceedWithoutDeps,
    endoCostDetails.isLoading,
  ]);

  return (
    <Shadow variant="bottom">
      <StyledBottomNavigation>
        {isBackEnabled && (
          <LoopButton
            size="medium"
            variant={
              !isNextEnabled || primaryLoading || secondaryLoading
                ? 'disabled'
                : backButtonVariant!
            }
            onClick={handleSecondaryClick}
            isLoading={secondaryLoading}
          >
            {backButtonText}
          </LoopButton>
        )}
        <LoopButton
          size="medium"
          variant={
            !isNextEnabled || primaryLoading || secondaryLoading
              ? 'disabled'
              : 'filled'
          }
          onClick={handleNextStep}
          isLoading={primaryLoading}
        >
          {buttonText}
        </LoopButton>
      </StyledBottomNavigation>
      <CancellationModal
        isBackBtnModalVisible={false}
        isCancelModalVisible={false}
        setCancelModalVisible={() => {}}
        setBackBtnModalVisible={() => {}}
        onConfirmCancelClick={() => {}}
        lowCDModalVisible={lowCDModalVisible}
        setLowCDModalVisible={setLowCDModalVisible}
        submitEndorsements={submitEndorsementRequest}
        isSubmitting={primaryLoading || secondaryLoading}
      />
    </Shadow>
  );
};
export default SingleAddMembersFooter;
